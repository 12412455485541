import cn from 'classnames';
import { Translate } from 'i18n';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { customMediaQuery } from '../../styled';
import GetFullAccessButton from './GetFullAccessButton';

type tProps = {
  className?: string;
  textTranslationKey?: string;
};
const GetFullAccessMessageWithButton: FC<PropsWithChildren<tProps>> = ({
  className,
  textTranslationKey,
  children
}) => {
  return (
    <SNoAccessBlock className={cn(className)}>
      {textTranslationKey ? (
        <STranslate
          str={textTranslationKey}
          params={{ defaultValue: 'You need a license to access this resource' }}
        />
      ) : (
        children
      )}

      <SGetFullAccessButton />
    </SNoAccessBlock>
  );
};

export default GetFullAccessMessageWithButton;

// STranslate and SGetFullAccessButton are needed to be reused in SNoAccessBlock styles
const STranslate = styled(Translate)``;
const SGetFullAccessButton = styled(GetFullAccessButton)``;

const SNoAccessBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 16px;
  background-color: #00000033;
  box-shadow: var(--card-shadow);

  & ${STranslate} {
    font-size: 1.125rem;
    color: white;
    vertical-align: middle;
    ${customMediaQuery('tablet')} {
      font-size: 1.375rem;
    }
  }

  & ${SGetFullAccessButton} {
    width: 100%;
    max-width: 100%;
    padding: 6px;
    margin-block-start: 12px;

    ${customMediaQuery('tablet')} {
      margin-inline-start: 1rem;
      padding: 16px;
      max-width: max-content;
    }
  }

  ${customMediaQuery('tablet')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-inline-start: 28px;
  }
`;
